<template>
  <section>
    <router-link class="come-back" :to="getLink">
      <div></div>
      {{ $store.getters.GET_LANG ? 'Вернуться в раздел “Все анонсы”' : 'Go back to the “All announcements " section”' }}
    </router-link>

    <div class="two-columns-wrapper">
      <main>
        <announcement-content/>
      </main>

      <similar-block-region :title="$store.getters.GET_LANG ? 'Похожие анонсы' : 'Similar announcements'"
                            :getNews="$store.getters.getPublicAnnouncements.slice(0,3)"
                            card-link="announcement"
                            link="/news?tab=Announcements"
                            v-if="$store.getters.getCurrentAnnouncement" class="block_region"/>
    </div>

  </section>
</template>
<script>
import AnnouncementContent from '@/components/news/AnnouncementContent'
import SimilarBlockRegion from '@/components/activitiesSurveys/SimilarBlockRegion'
import setMeta from '@/mixins/setMeta'

export default {
  name: 'Announcement',

  watch: {
    '$store.getters.getCurrentAnnouncement' () {
      if (this.$store.getters.getCurrentAnnouncement) {
        document.title = this.$store.getters.getCurrentAnnouncement.title
        this.setMeta('description', this.$store.getters.getCurrentAnnouncement.description)
        this.$store.commit('setFilterRubric', this.$store.getters.getCurrentAnnouncement.rubric)
        this.$store.commit('setFilterId', this.$store.getters.getCurrentAnnouncement.id)
        this.$store.dispatch('getPublicAnnouncements')
        this.$store.commit('setFilterRubric', null)
        this.$store.commit('setFilterId', null)
      }
    }
  },

  beforeDestroy () {
    document.title = 'Общественная палата Российской Федерации'
    this.deleteMeta('description')
    this.$store.commit('setCurrentAnnouncement', null)
  },

  computed: {
    getLink () {
      const params = this.$store.getters.getRouteParams
      return `/news?tab=Announcements&page=${params.page || 1}`
    }
  },

  mixins: [setMeta],

  components: {
    SimilarBlockRegion,
    AnnouncementContent
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .come-back {
    width: 90%;
  }
  .block_region {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .two-columns-wrapper {
    flex-direction: column;
  }
}

</style>
