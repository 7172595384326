<template>
  <section class="white-container news" v-if="getCurrentAnnouncement">
        <!-- категория новости и дата -->
        <div class="news-header">
            <p class="news-header__rubric" @click="setRubric()">
              {{ getCurrentAnnouncement.rubric_model[getLang ? 'title' : 'title_eng'] }}
            </p>

            <p class="news-header__date">
              {{ getFormattedDate(getCurrentAnnouncement.date, true, true) }}
            </p>
        </div>

        <!-- заголовок новости -->
        <h5>{{ getCurrentAnnouncement[getLang ? 'title' : 'title_eng'] }}</h5>

        <!-- просмотры и комментарии -->
        <div class="news-info">
          <p v-if="getComments.length" class="news-info__comment">{{ getComments.length }}</p>
          <p class="news-info__views">{{ getCurrentAnnouncement.view_count }}</p>
        </div>

        <!-- описание анонса -->
        <p class="news__description">{{ $store.getters.GET_LANG ? getCurrentAnnouncement.description : getCurrentAnnouncement.description_eng }}</p>

        <!-- превью общей картинки новости  -->
        <figure v-if="getCurrentAnnouncement && getCurrentAnnouncement.preview_photo" class="news-img"
            :style="`background: url('${getCurrentAnnouncement.preview_photo}') center / cover`">
        </figure>

        <component v-for="(contentBlock, index) in getContentBlocks"
                   :is="contentBlock.component"
                   :content-block="contentBlock"
                   :key="`contentBlock${index}`"
                   class="content__block"/>

        <!-- новостные теги -->
        <tags-list :tags="getTagList"/>

        <!-- соц. иконки -->
        <share-social :content="getCurrentAnnouncement"/>
    </section>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import ShareSocial from '@/components/news/ShareSocial'
import TricolorLine from '@/components/TricolorLine'
import CommentCard from '@/components/news/CommentCard'
import TextContentBlock from './TextContentBlock'
import RightPicContentBlock from './RightPicContentBlock'
import LeftPicContentBlock from './LeftPicContentBlock'
import TwoPicContentBlock from './TwoPicContentBlock'
import formatDate from '@/mixins/formatDate'
import SocialNetworkIcons from './SocialNetworkIcons'
import TagsList from './TagsList'

export default {
  name: 'AnnouncementContent',

  data () {
    return {
      carouselData: 0,
      views: 86,
      getTempIdNews: 0
    }
  },

  mounted () {
    this.$store.dispatch('getPublicAnnouncementById', this.$route.params.id)
  },

  watch: {
    '$route' () {
      this.$store.dispatch('getPublicAnnouncementById', this.$route.params.id)
      this.$store.commit('setCurrentAnnouncement', null)
    },

    getCurrentAnnouncement () {
      this.$nextTick(function () {
        this.setLightBoxToImages()
      })
    },

    getLang () {
      this.$store.dispatch('getPublicAnnouncementById', this.$route.params.id)
    }
  },

  methods: {
    setLightBoxToImages () {
      const contentBlocks = document.querySelectorAll('.content__block');

      [].forEach.call(contentBlocks, (contentBlock) => {
        const images = contentBlock.querySelectorAll('img');

        [].forEach.call(images, (image) => {
          image.addEventListener('click', (e) => {
            this.$store.commit('setLightBoxImage', image.getAttribute('src'))
          })
        })
      })
    },

    slidePrev () {
      this.$refs.carousel.slidePrev()
    },

    slideNext () {
      this.$refs.carousel.slideNext()
    },

    changeSlider (num) {
      this.$refs.carousel.slideTo(num)
    },

    updateCarousel (payload) {
      this.carouselData = payload.currentSlide
    },

    setRubric () {
      this.$store.commit('setFilterRubric', this.getCurrentAnnouncement.rubric)
      this.$router.push('/news?tab=Announcements')
    }
  },

  computed: {
    getLang () {
      return this.$store.getters.GET_LANG
    },

    getCurrentAnnouncement () {
      return this.$store.getters.getCurrentAnnouncement
    },

    getContentBlocks () {
      if (this.getCurrentAnnouncement && this.getCurrentAnnouncement.content_blocks) {
        return this.getCurrentAnnouncement.content_blocks.map((contentBlock) => {
          let component = contentBlock.content_block_type.code.toLowerCase().split('_')

          component = component.reduce((componentName, word) => {
            return componentName + (word && word.charAt(0).toUpperCase() + word.slice(1))
          }, '')

          return {
            ...contentBlock,
            component: component + 'ContentBlock'
          }
        })
      } else {
        return []
      }
    },

    getNews () {
      return {
        content: null
      }
      /*
      const content = this.$store.getters.getContent
      return content.content && content.content.news && content.content.news.length ? content.content.news[this.getTempIdNews] : ''
      */
    },

    getTagList () {
      const news = this.getCurrentAnnouncement
      return (this.$store.getters.GET_LANG ? news.tags_list : news.tags_list_eng) ? (this.$store.getters.GET_LANG ? news.tags_list : news.tags_list_eng) : []
    },

    getComments () {
      const news = this.getNews
      return news.comment ? news.comment : []
    },

    getImages () {
      const news = this.getNews
      return news.content && news.content.image ? news.content.image : []
    }
  },

  components: {
    TagsList,
    SocialNetworkIcons,
    ShareSocial,
    CommentCard,
    TricolorLine,
    Hooper,
    Slide,
    TextContentBlock,
    LeftPicContentBlock,
    RightPicContentBlock,
    TwoPicContentBlock
  },

  mixins: [
    formatDate
  ]
}
</script>

<style lang="scss" scoped>
  .news__description {
    font-style: italic;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.375rem;
    color: rgba(23, 23, 23, 0.8);
    margin-bottom: 1.625rem;
  }

.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
.news {
  .content__block {
    margin-bottom: 1.25rem;
  }

  h5 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;

    margin-bottom: 1.18rem;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.18rem;

    &__rubric {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .375rem .625rem;
      text-transform: uppercase;
      color: #003E78;
      opacity: 0.8;
      cursor: pointer;
    }
    &__date {
      font-size: 0.875rem;
      margin-left: 0.625rem;
    }
  }

  &-info {
    display: flex;
    align-items: center;

    &__views,
    &__comment {
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      margin-bottom: 1.3rem;

      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 125%;

      color: #5A79A8;
    }

    &__views::before{
      background: url(../../assets/icons/view.svg);
    }
    &__comment::before{
      background: url(../../assets/icons/message.svg);
    }
    &__views::before,
    &__comment::before {
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 0.6rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &-content__left-img {
    width: 15rem;
    height: 11.25rem;
    float: left;
    margin: 0 1.56rem 1.56rem 0;

    border-radius: 0.5rem;
  }

  &-content__right-img {
    float: right;
    margin: 0 0 1.56rem 1.56rem;
  }

  ::v-deep &-content__text{
    margin-bottom: 2.5rem;

    p {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 2.5rem;

    p {
      margin: 0.35rem 0.625rem 0.35rem 0;
    }
  }

  &-social {
      border-top: 1px solid #D5D6D9;
      padding-top: 2.5rem;
  }

  &-img {
    width: 100%;
    height: 27.5rem;
    border-radius: 0.5rem;

    margin-bottom: 2.5rem;
  }
  .news-content__gallery {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
    .slider-nav{
    display: flex;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;

    &__arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        margin: 0 auto;

        svg{
            width: 2.5rem;
            height: 2.5rem;

            cursor: pointer;
        }
    }
  }

}

.news__stream {
    margin-top: 1.62rem;
    position: relative;
    margin-bottom: 2.5rem;
  }

  .slider-stream__item {
    display: flex;
  }

  .slider-stream__video {
    width: 55.8rem;
    height: 27.5rem;
    display: flex;
  }

.preview{
    display: flex;
    width: 100%;
    overflow-x: auto;

    .img {
        display: flex;
        min-width: 4.56rem;
        height: 3.31rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 1.25rem;

        background: #9CA0A7;
        opacity: 0.5;
        cursor: pointer;

        &:last-child{
            margin-right: auto;
        }
        &:first-child{
            margin-left: auto;
        }
        &.active-img{
            border: 0.2rem solid #8ABD1F;
            opacity: 1;
        }
    }
}

.comments {
  margin-top: 3.125rem;

  .line_wrapper {
    padding: 1.375rem 0;
  }

}
  @media screen and (max-width: 768px){
    .ckEditor-html {
      ::v-deep {
        iframe{
          width: 100% ;
        }
      }
    }
    .news {

      &-img {
        width: 100%;
        height: 21.81rem;
      }

      .news-content__gallery {
        width: 100%;
      }

      .slider-stream__video {
        width: 45rem;
        height: 21.81rem;
      }

    }
  }
   @media screen and (max-width: 420px){
    .news {
      ::v-deep * {
        font-size: 4vw;
      }

      &-header {
        flex-wrap: wrap;
      }

      &-content__left-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 1.56rem 1.56rem 0;
      }

      &-content__right-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 0 1.56rem 1.56rem;
      }

      &-img {
        width: 100%;
        height: 12.5rem;
      }

      &-social {
        padding-top: 1.375rem;
      }

      .slider-stream__video {
        width: 18rem;
        height: 12.5rem;
      }

      .slider-nav{
        display: none;
      }

    }
  }
</style>

<style lang="scss">

  .slider-stream {
    overflow: hidden;
    width: 55.8rem;
    height: 100%;
    border-radius: 0.5rem;

    &.hooper {
        height: 100%;
    }

    .hooper-list {
        .hooper-track{
            display: flex;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }
    }
  }

  .hooper-sr-only {
    display: none;
  }

  @media screen and (max-width: 768px){
    .slider-stream {
      width: 45rem;
    }
  }
  @media screen and (max-width: 420px){
    .slider-stream {
      width: 18rem;
    }
  }

</style>
